import React from 'react';
import styled from 'styled-components';
import ComponentWrapper from '../layout/ComponentWrapper';
import { Heading, Text, WYSIWYG } from '../../util/typography';
import * as colors from '../../util/colors';
import { NotationBlock } from '../../util/typography';
import GrayLine from '../../assets/line-down-gray.svg';
import GreenLine from '../../assets/line-down-green.svg';
import GrayArrow from '../../assets/arrow-down-gray.svg';
import GreenArrow from '../../assets/arrow-down-green.svg';
import AndConditionPlus from '../../assets/icon-plus.svg';

const LineKeyMap = {
    'line-gray': GrayLine,
    'line-green': GreenLine,
    'arrow-gray': GrayArrow,
    'arrow-green': GreenArrow
};

const OverviewTableWrapper = styled.div`
    margin: calc(15px + 1%) -10px;
    margin-bottom: 0;
`;

const OverviewTable = styled.table`
    table-layout: fixed;
    display: inline-table;
    border-spacing: 0;
    border: none;
    width: 100%;
    border-collapse: collapse;
    td {
        position: relative;
        padding: 0;
        vertical-align: top;
        height: 30px;
    }
    tr:last-child td > * {
        margin-bottom: 0;
    }
`;


// box-gradient
// box-small-title
// box-green
// box-dark
// box-gray
// box-multi-color
// box-multi-color-green
// box-multi-color-gray (default)
// box-hidden
// line-gray
// line-green
// arrow-gray
// arrow-green


const BoxCell = styled.dl`
    border-radius: 8px;
    margin: 10px;
    border-radius: 8px;
    border-style: solid;
    border-width: 1px;
    background-color: ${colors.white};
    border-color: ${colors.pebble};
    
    &:not(.box-multi-color-gray):not(.box-multi-color-green) {
        padding: clamp(8px, 3vw, 26px) clamp(4px, 3%, 20px);
    }

    &:not(.box-text-align-left) > *:only-child {
        text-align: center;
    }

    dt:not(:only-child) {
        padding-bottom: 5px;
    }

    dd {
        margin-left: 0;   
    }

    &.box-gradient {
        color: white;
        background-image: radial-gradient(circle at 23% 0, #006ead, #36864a 87%);
        dt {
            padding-bottom: 5px;
        }
    }

    &.box-green {
        background: ${colors.keytrudaGreen};
        border-color: ${colors.keytrudaGreen};
    }

    &.box-gray {
        background: ${colors.mist};
    }

    &.box-dark {
        color: ${colors.white};
        border-color: ${colors.pebble};
        background: ${colors.charcoal}
    }

    

    &.box-multi-color-gray {
        dt {
            border: 1px solid ${colors.pebble};
            background: ${colors.mist};
        }
        dd {
            border: 1px solid ${colors.pebble};
            background: ${colors.white};
        }
        // OK...so this was in the general zeplin designs but was not in any of the
        // study design instances if we wver need it (to make description boxes sit side-by-side)
        // here it is :)
        // &.description-split {
        //     display: flex;
        //     flex-wrap: wrap;
        //      dt {
        //         flex: 0 1 100%;
        //         color: ${colors.white};
        //         border-color: ${colors.pebble};
        //         background: ${colors.charcoal};
        //     }
        //     dd {
        //         flex: 0 1 50%;
        //         &:nth-of-type(2) {
        //             border-left: none;
        //         }
        //     }
        // }
    }
    &.box-multi-color-green {
        dt {
            border: 1px solid ${colors.keytrudaGreen};
            background: ${colors.keytrudaGreen};
        }
        dd {
            border: 1px solid ${colors.keytrudaGreen};
            background: ${colors.white};
        }
    }
    &.box-multi-color-gray, &.box-multi-color-green {
        border: none;
        
        dt {
            border-radius: 8px 8px 0 0;
            padding: clamp(8px, 3vw, 26px) clamp(4px, 3%, 20px);
            
            // there is a better way to do these, but for now, this will work
            // TODO: nomalize dt title and subtitle in the data
            // right now this is only being used on tnbc study design
            
            > *:first-child {
                margin-top: 0;
            }
            > *:last-child {
                margin-bottom: 0;
            }
            > *:not(:only-child):not(:last-child) {
                padding-bottom: 5px;
            }
            > *:not(:first-child):not(:last-child) {
                padding-bottom: 5px;
            }
        }
        dd {
            border-top: none;
            padding: 0 clamp(4px, 3%, 20px);
            
            &:first-of-type {
                padding-top: clamp(8px, 3vw, 10px);
            }
            &:not(:last-child) {
                border-bottom: none !important;
            }
            &:last-child {
                border-radius: 0 0 8px 8px;
                padding-bottom: clamp(8px, 3vw, 26px);
            }
            > * {
                padding-top: 16px;
            }
        }
    }
`;

const LineCell = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url(${(props) => LineKeyMap[props.type] || ''});
    background-repeat: repeat-y;
    background-position: center center;
`;


const StudyTitle = styled(Heading.H4)`
    margin-bottom: 10px;
`;

const StudyDescription = styled(WYSIWYG)`


`;

const ArmTitle = styled(Heading.Subtitle)`
    margin-top: 30px;
    + div {
        margin-top:0;
    }
`;


const MultiFlowWrapper = styled.div`
    --gutter: 20px;
    --breakpoint: 40rem;
    --modifier: calc((var(--breakpoint) - 100%) * 999);

    display: flex;
    flex-wrap: wrap;
    margin: 0 calc(var(--gutter) / 2 * -1);

`;

const MultiFlowItem = styled.div`
    flex-grow:1;

    flex-basis: max(var(--width, var(--auto-width)), var(--modifier));
    max-width: max(var(--width, var(--max-width)), var(--modifier));
    
    margin: calc(var(--gutter) / 2);
    margin-bottom: 0;
    margin-top: 0;
    
    --auto-width: calc(99.999% * var(--w, 0) - var(--gutter));
    --max-width:  calc(99.999% * var(--w) - var(--gutter));
`;

const AndConditionalWrapper = styled.div``

const AndConditional = styled.div`
    height: 16px;
    background: url(${AndConditionPlus}) no-repeat center center;
`;

const OrConditional = styled.div`
    text-align: center;
    &:before {
        content: 'Or';
        // TODO: import global styles
        color: ${colors.ink};
        font-weight: 600;
    }
`;

const createMarkup = content => {
    return { __html: content };
};


// Types
// TODO: break out into documentation

// box-gradient
// box-small-title
// box-green
// box-dark
// box-gray
// box-multi-color-green
// box-multi-color-gray (default)
// box-hidden
// line-gray
// line-green
// arrow-gray
// arrow-green

// description {array of objects}
    // text - string
    // textCenter - boolean
    // conditional - string : "and" | "or"



const isString = value => {
    return typeof value === 'string';
};

export const FlowTable = props => {

    return (
        <OverviewTableWrapper>
                <OverviewTable>
                    <tbody>
                        {props.data && props.data.map((row, rowIndex) => {
                            return (
                                <tr key={`${rowIndex}-row`}>
                                    {row.map((cell, cellIndex) => {

                                        cell.type = cell.type || '';

                                        const CellStyle = (cell.type.includes('box') || cell.type === '') ? BoxCell : LineCell ;
                                        
                                        // because we may have two description box containers side by side, we need to 
                                        // normalize the data to an array
                                        const normalizedDescriptionArr = (cell.description) ? (Array.isArray(cell.description)) ? cell.description : [cell.description] : [];

                                        // let's determine the proper heading style based on the type assigned
                                        let TitleHeading = Heading.H7;
                                        if (cell.type.includes('box-gradient')) TitleHeading = Heading.H5;
                                        if (cell.type.includes('box-small-title')) TitleHeading = Text.Footnote;

                                        return (
                                            <td
                                                key={`${rowIndex}-${cellIndex}-cell`}
                                                colSpan={cell.colspan || '1'}
                                                rowSpan={cell.rowspan || '1'}
                                            >
                                                {cell.type !== 'box-hidden' && 
                                                    <CellStyle type={cell.type} className={cell.type}>
                                                        {cell.title && (
                                                            <TitleHeading as="dt" theme={(cell.type.includes('box-gradient') || cell.type.includes('box-dark')) ? 'light' : 'dark'} dangerouslySetInnerHTML={createMarkup(
                                                                cell.title
                                                            )} />
                                                        )}
                                                        
                                                        {normalizedDescriptionArr.map(description =>
                                                            <>
                                                                {isString(description)  && 
                                                                    <WYSIWYG as="dd" theme={(cell.type.includes('box-gradient') || cell.type.includes('box-dark')) ? 'light' : 'dark'} dangerouslySetInnerHTML={createMarkup(
                                                                        description
                                                                    )} />
                                                                }
                                                                
                                                                {!isString(description) && 
                                                                    <dd>

                                                                        {description.conditional && 
                                                                            <>
                                                                                { description.conditional === 'and' && 
                                                                                <AndConditionalWrapper>
                                                                                    <AndConditional></AndConditional>
                                                                                </AndConditionalWrapper>
                                                                                }
                                                                                { description.conditional === 'or' && 
                                                                                    <OrConditional></OrConditional>
                                                                                }
                                                                            </>
                                                                        }
                                                                        <WYSIWYG 
                                                                            as="div" 
                                                                            theme={(cell.type.includes('box-gradient') || cell.type.includes('box-dark')) ? 'light' : 'dark'} 
                                                                            style={ description.textCenter ? { textAlign:'center' } : {}}
                                                                            dangerouslySetInnerHTML={createMarkup(
                                                                                description.text
                                                                            )
                                                                        } />
                                                                    </dd>
                                                                }
                                                            </>
                                                        )}
                                                    </CellStyle>
                                                }
                                            </td>
                                        )
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </OverviewTable>
            </OverviewTableWrapper>
    )

}

export const StudyDesignOverview = props => {
        
    return (
        
        <ComponentWrapper>
            
            <StudyTitle as='H3' dangerouslySetInnerHTML={createMarkup(props.title)}></StudyTitle> 

            <StudyDescription dangerouslySetInnerHTML={createMarkup(props.description)}></StudyDescription> 

            <FlowTable {...props} />

        </ComponentWrapper>
    );
};

export const StudyDesignArms = props => {
        
    return (
        
        <ComponentWrapper>
            
            <StudyTitle as='H3' dangerouslySetInnerHTML={createMarkup(props.title)}></StudyTitle> 

            <StudyDescription dangerouslySetInnerHTML={createMarkup(props.description)}></StudyDescription> 

            <MultiFlowWrapper>
                {props.arms.map((flow, flowIndex) => 
                    <MultiFlowItem key={`${flowIndex}-flow`}>
                        
                        <ArmTitle dangerouslySetInnerHTML={createMarkup(flow.title)}></ArmTitle>
                        
                        <FlowTable data={flow.data} />
                    </MultiFlowItem>
                )}
            </MultiFlowWrapper>

            {(props.footnotes || props.definitions) && <NotationBlock footnotes={props.footnotes} definitions={props.definitions} />}

        </ComponentWrapper>
    );
};

export const StudyCopyBlock = props => {
        
    return (
        
        <ComponentWrapper>
            
            {props.title && <StudyTitle as='H3' dangerouslySetInnerHTML={createMarkup(props.title)}></StudyTitle>}

            {props.description && <StudyDescription dangerouslySetInnerHTML={createMarkup(props.description)}></StudyDescription>}
            
            {(props.footnotes || props.definitions) && <NotationBlock footnotes={props.footnotes} definitions={props.definitions} />}

        </ComponentWrapper>
    );
};